// show-hide password
@keyframes password-line {
  from {
    opacity: 0;
    top: 80px;
  }

  to {
    opacity: 1;
  }
}

@keyframes password-line-off {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    top: 80px;
  }
}

.show-hide,
.password-line {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
  }
}

.show-hide {
  padding: 0px;
}

.password-line {
  &.show {
    animation: password-line 0.8s;
    display: block;

    svg {
      top: 5px;
    }
  }

  &.off {
    animation: password-line-off 0.8s;
    opacity: 0;
  }
}

// end show-hide password

@keyframes popup-show {
  0% {
    right: -20vw;
    opacity: 0.01;
    padding: 0;
    margin: 0;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(40px);
  }

  50% {
    transform: translateX(calc((-105% - 40px) + 100vw));
  }

  100% {
    transform: translateX(40px);
  }
}

@keyframes scroll-reverse {
  0% {
    transform: translateX(calc((-105% - 40px) + 100vw));
  }

  50% {
    transform: translateX(40px);
  }

  100% {
    transform: translateX(calc((-105% - 40px) + 100vw));
  }
}

.marquee {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  span {
    display: inline-flex;
    transform: translateX(40px);
    animation: scroll 70s infinite linear;
    // color: $main_color;

    small {
      // color: $bg-color;
      // text-shadow: -2px 0 $accent_color, 0 2px $accent_color,
      //   2px 0 $accent_color, 0 -2px $accent_color;
      // margin-left: 30px;
      // margin-right: 30px;
    }
  }

  &-reverse {
    span {
      transform: translateX(calc((-105% - 40px) + 100vw));
      animation: scroll-reverse 60s infinite linear reverse;

      //       animation: none;
      // transform: none;
      // text-align: right;
    }
  }
}