@media screen and (max-width: 1200px) {}

@media screen and (min-width: 991px) {
    .desktop-hide {
        display: none !important;
    }
}

@media screen and (max-width: 991px) {
    .mobile-hide {
        display: none !important;
    }

    html {
        overflow-x: hidden;
    }

    h1,
    .h1 {
        @include h1_mobile;
    }

    h2,
    .h2 {
        @include h2_mobile;
    }

    h3,
    .h3 {
        @include h3_mobile;
    }

    h4,
    .h4 {
        @include h4_mobile;
    }

    .body_1 {
        @include body_1_mobile;
    }

    .body_2 {
        @include body_2_mobile();
    }

    body {
        @include body_1_mobile;

        &.page-ready {
            header.page-header {
                top: 15px;
            }
        }

        &.menu-open {
            header.page-header {
                .left-side {
                    opacity: 1;
                    pointer-events: all;
                    visibility: visible;
                    row-gap: 10px;
                }
            }
        }
    }

    header.page-header {

        .left-side {
            position: fixed;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            max-height: 100vh;
            background-color: $black_1;
            margin: 0;
            padding-top: 72px;

            flex-direction: column;
            justify-content: center;
            align-items: center;

            opacity: 0;
            pointer-events: none;
            visibility: hidden;

            transition: $transition;
        }

        .right-side {
            column-gap: 10px;
        }
    }

    footer.page-footer {
        padding-top: 30px;
        padding-bottom: 40px;
        // text-align: center;

        .nav-wrap {
            &-con {
                // flex-direction: column;
                flex-wrap: wrap;
                margin-top: 30px;
                margin-bottom: 48px;
                row-gap: 32px;
                column-gap: 56px;

                ul {
                    margin-top: 7px;
                    margin-bottom: 18px;
                    row-gap: 5px;
                }
            }

            .body_bold {
                margin-bottom: 24px;
            }
        }

        .right-side {
            text-align: left;

            .payments-svg {
                justify-content: flex-start;

                svg {
                    margin-right: 16px;
                }
            }
        }
    }

    // ============================================ PAGES ============================================

    .home-page {
        &.banner {
            margin-top: 40px;
            margin-bottom: 70px;
            // text-align: center;

            .button {
                // margin-left: auto;
                // margin-right: auto;
            }

            .left-side {
                p {
                    margin-top: 16px;
                    margin-bottom: 35px;
                }
            }

            .right-side {
                margin-top: 30px;

                .animate-svg {
                    right: 0;
                }
            }
        }

        &.payments-info {
            padding-bottom: 70px;

            &.bg-white-first {
                margin-top: 45vw;
            }

            .row-title {
                margin-bottom: 40px;

                h2 {
                    margin-bottom: 20px;
                }
            }

            .row-content {
                .custom-cart {
                    margin-bottom: 20px;

                    h4 {
                        margin-top: 0;
                        margin-bottom: 6px;
                    }
                }
            }
        }

        &.explore-new {
            padding-bottom: 60px;

            .logos-wrap {
                row-gap: 20px;
                column-gap: 30px;
                justify-content: center;
                margin-right: 0;

                svg {
                    max-width: 75px;
                    height: unset;
                }
            }

            .container {
                .col-lg-12 {
                    flex-direction: column;
                    column-gap: 0;
                }
            }

            .img-wrap {
                margin-left: 0;
            }

            .content-wrap {
                margin-top: 0;
                margin-left: 0;

                p {
                    margin-top: 15px;
                    margin-bottom: 35px;
                }
            }
        }

        &.ehe-keys {
            &.bg-white-last {
                margin-bottom: 45vw;
            }

            .row-title {
                margin-bottom: 30px;

                h2 {
                    margin-bottom: 10px;
                }
            }

            .row-content {

                >div {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    border: unset;
                }

                .content-left-side {
                    padding-top: 30px;
                    padding-bottom: 0;
                    border-top: 1px solid $grey_1;

                    h3 {
                        margin-left: 20px;
                    }
                }

                .col-lg-5 {
                    padding-top: 20px;
                }
            }
        }
    }

    .payments-page {
        &.banner {
            .left-side h2 {
                width: 100%;
                max-width: 100%;
            }
        }

        &.payments-icons {
            .custom-cart {
                width: 75px;
                height: 75px;
                margin-left: 10px;
                margin-right: 10px;

                img,
                svg {
                    max-width: 68%;
                }
            }

            .marquee {
                padding-top: 30px;
                padding-bottom: 80px;

                &-reverse {
                    margin-top: -35px;
                    margin-bottom: -20px;
                    padding-top: 0;
                    position: relative;
                    z-index: 1;
                }
            }
        }

        &.carts-wrap {
            padding-top: 5px;
            padding-bottom: 50px;

            .row {
                margin-bottom: 0;

                &:first-child {
                    .col-lg-5:first-child {
                        .custom-cart {
                            margin-top: 0;
                        }
                    }
                }
            }

            .custom-cart {
                width: 60px;
                height: 60px;
                margin-top: 60px;

                svg,
                img {
                    max-width: 45%;
                    height: unset;
                }
            }

            h4 {
                margin-top: 25px;
                margin-bottom: 10px;
            }

            .body_2 {
                // margin-bottom: 50px;
            }
        }

        &.chose-block {
            .container {
                padding-top: 50px;
                padding-bottom: 70px;
            }

            .row {
                margin-bottom: 0;
            }

            .col-title {
                margin-bottom: 50px;

                p {
                    margin-top: 13px;
                    max-width: 100%;
                }
            }

            .custom-cart {
                min-height: unset;
                width: 100%;
                margin-bottom: 20px;

                h4 {
                    margin-top: 15px;
                    margin-bottom: 10px;
                }
            }
        }

        &.tech-features {
            &.bg-white-last {
                margin-bottom: 45vw;

                .bg-white-last-bg-wrap {
                    bottom: -20vw;
                }
            }

            .row-title {
                h2 {
                    margin-bottom: 15px;
                }
            }

            .col-tabs {
                &-navigation {
                    margin-top: 40px;
                    margin-bottom: 10px;
                    padding-left: 0;
                    padding-right: 0;
                    margin-left: 16px;
                    margin-right: 16px;

                    .slick-dots {
                        overflow-x: scroll;
                        flex-wrap: nowrap;
                        white-space: nowrap;
                        padding-bottom: 20px;


                        /*FireFox*/
                        scrollbar-width: none;
                        /*IE10+*/
                        -ms-overflow-style: -ms-autohiding-scrollbar;

                        &::-webkit-scrollbar {
                            /*Chrome, Safari, Edge*/
                            display: none;
                        }


                        *::-webkit-scrollbar {
                            width: 0;
                            padding: 2px;
                        }

                        *::-webkit-scrollbar-track {
                            background: transparent;
                            border: 1px solid transparent;
                            // border-radius: 8px;
                        }

                        *::-webkit-scrollbar-thumb {
                            background-color: transparent;
                            border-radius: 3px;
                            width: 4px;

                            // background-color: red;
                            border: 4px solid transparent;
                            border-radius: 6px;
                            background-clip: padding-box;
                        }
                    }
                }

                &-wrap {}

                &-item {
                    margin-right: 25px;

                    h3 {
                        margin-bottom: 15px;
                    }
                }
            }

        }
    }

    .tech-features-page {
        &.banner {
            .right-side {
                text-align: center;
                margin-top: 0;

                img {
                    margin-top: -6%;
                    margin-bottom: -6%;
                }
            }
        }

        &.main-carts-sect {
            margin-top: 100px;
            margin-bottom: 200px;

            .row-item {
                row-gap: 15px;
                padding-bottom: 40px;
                margin-bottom: 40px;
            }

            .item-left-side {
                column-gap: 35px;

                .custom-cart {
                    width: 65px;
                    height: 65px;

                    svg,
                    img {
                        width: 40%;
                        height: unset;
                    }
                }
            }
        }
    }

    .antifraud-page {
        &.banner {
            .right-side {
                margin-top: 40px;
            }
        }

        &.main-carts-sect {
            margin-top: 45vw;
            margin-bottom: 200px;

            .bg-white-last {
                margin-bottom: 20vw;
            }

            .bg-white-last-bg-wrap {
                bottom: -20vw;
            }

            .row-title {
                margin-bottom: 0;

                h2 {
                    margin-bottom: 15px;
                }
            }

            .row-content {

                .custom-cart {
                    margin-top: 50px;
                    width: 60px;
                    height: 60px;

                    svg,
                    img {
                        max-width: 40%;
                        height: unset;
                    }
                }

                h4 {
                    margin-top: 20px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .contact-page {
        margin-top: 30px;
        margin-bottom: 10px;

        .row-title {
            h2 {
                margin-bottom: 10px;
            }
        }

        form {
            margin-bottom: 35px;

            .col-lg-6,
            .col-lg-12 {
                margin-bottom: 20px;
            }

            .button {
                margin-top: 20px;
                width: 100%;
            }
        }
    }

    .merchant-portal-page {
        &.banner {
            padding-bottom: 50px;

            .right-side {
                overflow: hidden;

                img {
                    right: -7%;
                }
            }
        }

        &.keep-track {
            padding-bottom: 60px;

            &.bg-white-first {
                margin-top: 40vw;

                .bg-white-first-bg-wrap {
                    top: -20vw;
                }

                .bg-white-first-circle {
                    top: calc(-20vw - 72px);
                }
            }

            .title {
                margin-bottom: 25px;
            }

            .item {
                margin-bottom: 15px;
                column-gap: 15px;

                &:last-child {
                    margin-bottom: 25px;
                }
            }
        }

        &.start-analyzing {
            .container {
                padding-top: 60px;
                padding-bottom: 0;
            }

            .row-title {
                row-gap: 15px;
                margin-bottom: 60px;
            }

            .row-content {
                .item {
                    margin-bottom: 50px;

                    &-header {
                        margin-bottom: 25px;
                        column-gap: 20px;
                    }

                    &-content {}
                }
            }
        }

        &.additional-benefits {
            padding-top: 65px;
            padding-bottom: 0;
            margin-bottom: 20vw;

            .col-title {
                margin-bottom: 40px;
            }

            .custom-cart {
                margin-bottom: 30px;

                p {
                    margin-top: 10px;
                }
            }
        }
    }

    .integration-page {
        &.effortless {
            padding-bottom: 60px;

            &.bg-white-first {
                margin-top: 270px;

                .bg-white-first-bg-wrap {
                    top: -30vw;
                }

                .bg-white-first-circle {
                    top: calc(-30vw - 72px);
                }
            }

            .row-title {
                margin-bottom: 50px;

                h2 {
                    margin-bottom: 15px;
                }
            }

            .row-content {
                .col-title {
                    margin-bottom: 30px;
                }

                .custom-cart {
                    margin-bottom: 15px;
                    min-height: unset;

                    p {
                        margin-top: 15px;
                    }
                }
            }
        }

        &.types-of-integration {
            .container {
                padding-top: 60px;
                padding-bottom: 70px;
            }

            .row-title {
                margin-bottom: 40px;

                .col-navigation {
                    margin-top: 20px;

                    .slick-dots {
                        display: flex;
                        flex-wrap: nowrap;
                        overflow-x: scroll;
                        padding-bottom: 10px;
                        -ms-overflow-style: none;
                        scrollbar-width: none;

                        button {
                            white-space: nowrap;
                        }

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                }
            }

            .row-content {
                .slider-types-item {
                    margin-left: 16px;
                    margin-right: 16px;

                    .row {
                        row-gap: 15px;
                    }

                    h3 {
                        margin-bottom: 20px;
                    }

                    .custom-cart-wrap {
                        margin-bottom: 30px;
                        column-gap: 30px;
                    }
                }

                .slick-list {
                    overflow: visible;
                }

                .slick-dotted.slick-slider {
                    margin-bottom: 30px;
                }


            }
        }

        &.additional-benefits {
            padding-top: 70px;

            &.bg-white-last {
                margin-bottom: 211px;

                .bg-white-last-bg-wrap {
                    bottom: -20vw;
                }
            }

            .row-title {
                margin-bottom: 0;
            }

            .custom-cart {
                &-wrap {
                    column-gap: 25px;
                    margin-top: 50px;
                    margin-bottom: 25px;
                }
            }
        }
    }


    .faq-page {
        &.banner {
            text-align: center;
        }

        &.main-content {
            margin-bottom: 210px;

            .nav-wrap {
                display: none;
            }
        }
    }

    // ============================================ COMPONENTS ============================================

    .feedback-container {
        margin-top: 45vw;
        margin-bottom: 70px;

        .row-title {
            margin-bottom: 30px;

            p {
                margin-top: 14px;
            }
        }

        .row-content {
            .slider-nav {
                order: 1;
                text-align: center;
                margin-top: 40px;
            }

            .custom-slider-item {
                margin-right: 20px;

                .feedback-author {
                    flex-direction: column;
                    row-gap: 20px;

                    .color-grey_2 {
                        margin-left: 0;

                        &::before {
                            top: -12px;
                            bottom: 0;
                            left: 3px;
                            margin: unset;
                        }
                    }
                }
            }
        }
    }

    .contact-container {
        margin-top: 100px;
        margin-bottom: 70px;
        flex-direction: column;

        .left-side {
            padding: 20px;
            padding-bottom: 40px;
            border-radius: 20px;

            .white-circle {
                transform: unset;
                top: calc(100% - 30px);
                left: 0;
                right: 0;
                // margin: a;
                // transform: translateX(-50%);
                width: 80px;

                svg {
                    max-width: 60px;
                    height: unset;
                }
            }
        }

        .right-side {
            margin-top: 20px;
            border-radius: 20px;
            padding: 20px;
            padding-top: 40px;
            max-width: 100%;

            .mailto {
                margin-top: 24px;
                margin-bottom: 32px;
            }

            .button {
                width: 100%;
            }
        }
    }

    // ============================================ OTHER ============================================

    .bg-white {
        &-first {

            &-bg-wrap {
                top: -20vw;
            }

            &-circle {
                top: calc(-20vw - 72px);
            }
        }

        &-last {
            &-bg {
                &-wrap {
                    bottom: -20vw;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 576px) {}

@media screen and (max-width: 475px) {}