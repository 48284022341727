$white: #F8F8F8;
$white_2: #EFEEF5;

$black_1: #151315;
$black_2: #2C2F31;
$black_3: #3F4348;
$black_4: #1D1E20;

$green_1: #6B53FF;
$green_2: #7F6AFF;
$green_3: #F3F2FA;

$grey_1: #E0DFE9;
$grey_2: #6E6C74;

$radial: radial-gradient(103.95% 114.26% at 97.19% 47.96%, #C4C0C9 0%, #9990AC 43.75%, #525055 100%);
$linear_2: linear-gradient(180deg, #CED6D3 0%, #A4B7AF 100%);



$main_color: $white_2;
$bg-color: $black_1;
$red: #BF1616;

$transition: 0.6s;

*,
*::after,
*::before {
    box-sizing: border-box;
}

@mixin body_1 {
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.3%;
}

@mixin body_2 {
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

@mixin body_bold {
    font-weight: 700;
}

@mixin h1 {
    font-family: "Mulish", sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 115%;
}

@mixin h2 {
    font-family: "Mulish", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

@mixin h3 {
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 133.3%;
}

@mixin h4 {
    font-family: "Mulish", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 166.667%;
}

@mixin body_1_mobile {
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.3%;
}

@mixin body_2_mobile {
    font-family: "Mulish", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

@mixin h1_mobile {
    font-family: "Mulish", sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 115%;
}

@mixin h2_mobile {
    font-family: "Mulish", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

@mixin h3_mobile {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 133.3%;
}

@mixin h4_mobile {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 166.667%;
}

h1,
.h1 {
    @include h1;
}

h2,
.h2 {
    @include h2;
}

h3,
.h3 {
    @include h3;
}

h4,
.h4 {
    @include h4;
}

.body_1 {
    @include body_1;
}

.body_2 {
    @include body_2();
}

.body_bold {
    @include body_bold();
}

.current-page {}

button,
input {
    text-decoration: none;
    outline: none;
    border: none;
    transition: $transition;

    &:hover,
    &:focus {
        outline: none;
        border: none;
        text-decoration: none;
    }
}

button {
    background-color: transparent;
}

html .woocommerce-checkout .woocommerce-billing-fields input,
input[type="text"],
input[type="password"],
input[type="email"],
textarea {
    padding: 16px 24px 16px 16px;
    border-radius: 8px;
    background-color: $black_4;
    color: $white_2;
    border: 1px solid transparent;
    outline: none;
    transition: $transition;
    margin-top: 0;
    display: inline-block;

    &::placeholder {
        @include body_1();
        color: $grey_2;
    }

    &:hover,
    &:focus {
        transition: $transition;
        background-color: $black_2;
    }

    &.wpcf7-not-valid {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $white_2;
        border-color: $red;

        &+.wpcf7-not-valid-tip {
            margin-left: 16px;
            margin-top: 0;
            font-size: 16px;
        }
    }

    &.not-empty {
        padding-top: 24px;
        padding-bottom: 8px;
    }
}

textarea {
    min-height: 112px;
    height: 112px;
}

input {
    &[disabled] {
        opacity: 0.6;
    }
}

a {
    color: $main_color;
    transition: $transition;
    display: inline-block;

    &:hover {
        color: $green_1;
        text-decoration: none;
        transition: $transition;
    }
}

// autocomplete background none
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    color: $main_color;
    -webkit-text-fill-color: $main_color;
    -webkit-box-shadow: 0 0 0px 1000px #1d1e20 inset;
}

.success {
    color: #4bb543;
    margin-bottom: 15px;
}

input:invalid,
.wpcf7-not-valid,
.wpcf7-not-valid::placeholder,
.wpcf7-not-valid-tip {
    // color: $red  !important;
}

.wpcf7-not-valid-tip {
    margin-top: 0;
    animation: popup-show $transition linear;
}

html .woocommerce #respond input#submit,
html .woocommerce a.button,
html .woocommerce button.button,
html .woocommerce input.button,
html .woocommerce #respond input#submit.disabled,
html .woocommerce #respond input#submit:disabled,
html .woocommerce #respond input#submit:disabled[disabled],
html .woocommerce a.button.disabled,
html .woocommerce a.button:disabled,
html .woocommerce a.button:disabled[disabled],
html .woocommerce button.button.disabled,
html .woocommerce button.button:disabled,
html .woocommerce button.button:disabled[disabled],
html .woocommerce input.button.disabled,
html .woocommerce input.button:disabled,
html .woocommerce input.button:disabled[disabled],
html .woocommerce #respond input#submit.alt,
html .woocommerce a.button.alt,
html .woocommerce button.button.alt,
html .woocommerce input.button.alt,
html .woocommerce-checkout #place_order,
.button {
    padding: 12px 48px;
    background-color: $green_1;
    color: $white;
    @include body_1();
    @include body_bold();
    border-radius: 50px;
    transition: $transition;
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
    text-align: center;

    &:hover,
    &:focus {
        transition: $transition;
        background-color: $green_2;
        color: $white;
    }
}

.button-grey {
    padding: 8px 32px;
    background-color: $grey_1;
    color: $black_2;
    border-radius: 50px;
    transition: $transition;
    text-decoration: none;
    border: none;
    outline: none;
    box-shadow: none;
    @include body_2();
    @include body_bold();

    &:hover,
    &:focus {
        transition: $transition;
        background-color: $white_2;
        color: $black_2;
    }
}

::selection {
    background-color: $green_1;
    color: $white_2;
}

.bg-white {
    background-color: #EFEFEF;
    color: $black_2;
    position: relative;

    &-first {
        margin-top: 192px;

        // border-radius: 50% 50% 0 0 ;

        &-circle {
            position: absolute;
            top: -72px - 192px;
            left: 50%;
            margin-left: -72px;
            border: 16px solid $black_1;
            border-radius: 100%;

            svg {
                border-radius: 100%;
            }
        }

        &-bg {
            position: relative;
            width: 120vw;
            left: -10vw;
            right: -10vw;
            height: 30vw;
            background-color: #EFEFEF;
            border-radius: 100%;
            border-radius: 100% 100% 0 0;

            &-wrap {
                position: absolute;
                z-index: -1;
                top: -192px;
                left: 0;
                right: 0;
                overflow: hidden;
                pointer-events: none;
            }
        }
    }

    &-last {
        margin-bottom: 211px;
        position: relative;

        &-bg {
            position: relative;
            z-index: 0;
            width: 120vw;
            left: -10vw;
            right: -10vw;
            height: 30vw;
            background-color: #EFEFEF;
            border-radius: 100%;
            border-radius: 0 0 100% 100%;

            &-wrap {
                position: absolute;
                z-index: -1;
                bottom: -211px;
                left: 0;
                right: 0;
                overflow: hidden;
                pointer-events: none;
            }
        }
    }
}

.color-grey_2 {
    color: $grey_2;
}

.color-green_1 {
    color: $green_1;
}

.link-arrow {
    color: $green_1;
    transition: $transition;
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
    @include body_bold();

    path {
        transition: $transition;
    }

    &:hover,
    &:focus {
        transition: $transition;
        color: $green_2;
        column-gap: 12px;

        path {
            fill: $green_2;
        }
    }
}

.popup-contact {
    position: fixed;
    z-index: 99;
    top: 16vh;
    right: 24px;

    &-item {
        border-radius: 10px;
        background-color: $black_2;
        padding: 16px 32px 16px 24px;
        position: relative;
        margin-bottom: 16px;
        max-width: 344px;
        width: 344px;
        max-width: 75vw;
        transition: $transition;
        animation: popup-show $transition linear;


        right: 0;
        opacity: 1;

        &.success {
            color: #3AB73A;
        }

        &.failed {
            color: $red;
        }

        .popup-item-close {
            position: absolute;
            top: 8px;
            right: 8px;

            path {
                transition: $transition;
            }

            &:hover,
            &:focus {
                path {
                    fill: $white;
                }
            }
        }

        &.hide {
            right: -20vw;
            opacity: 0.01;
            // height: 0;
            padding: 0;
            margin: 0;
        }
    }
}

// ================================================= OTHER ====================================================================================================

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    max-width: 1440px;
    padding-left: 16px;
    padding-right: 16px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto,
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    padding-left: 16px;
    padding-right: 16px;
}

.row {
    margin-left: -16px;
    margin-right: -16px;
}

.custom-cart {
    box-shadow: 0px 6px 12px 0px rgba(51, 61, 56, 0.10), 0px 23px 23px 0px rgba(51, 61, 56, 0.09), 0px 51px 31px 0px rgba(51, 61, 56, 0.05), 0px 91px 36px 0px rgba(51, 61, 56, 0.01), 0px 142px 40px 0px rgba(51, 61, 56, 0.00);
    // border-radius: 10%;
    background-color: $white;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
    padding: 2px;
  }
  
  *::-webkit-scrollbar-track {
    background: $bg-color;
    border: 1px solid $bg-color;
    // border-radius: 8px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: $main_color;
    border-radius: 3px;
    width: 4px;
  
    // background-color: red;
    border: 4px solid transparent;
    border-radius: 6px;
    background-clip: padding-box;
  }