@import "_libs.scss";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Ubuntu", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $bg-color;
  color: $main_color;
  @include body_1;
  overflow-x: hidden;

  opacity: 0;
  transition: $transition;


  header.page-header {
    top: -10vh;
    opacity: 0;
  }

  &.page-ready {
    opacity: 1;

    header.page-header {
      top: 23px;
      opacity: 1;
    }
  }

  &.menu-open {
    header.page-header {
      .burger-button {
        span {
          &:nth-child(1) {
            top: 50%;
            margin: auto;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            top: 50%;
            margin: auto;
            width: 0;
          }

          &:nth-child(3) {
            top: 50%;
            margin: auto;
            transform: rotate(-45deg);
            width: 100%;
          }
        }
      }
    }
  }
}

main {
  flex-grow: 1;
  margin-top: 73px;
}

section {
  width: 100%;
}

header.page-header {
  position: fixed;
  z-index: 9999;
  top: 23px;
  left: 0;
  right: 0;
  transition: $transition;

  .header-container {
    border-radius: 50px;
    background-color: $black_1;
    padding: 8px;
    padding-left: 16px;
  }

  .header-main {
    display: flex;
    align-items: center;
  }

  .left-side {
    display: flex;
    margin-left: 40px;

    a:not(.button-grey) {
      padding: 8px 14px;
      border-radius: 10px;
      @include body_2;
      @include body_bold;
      background-color: transparent;

      &:hover,
      &:focus {
        transition: $transition;
        background-color: $black_2;
        color: $grey_1;
      }
    }
  }

  .right-side {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 6px;

    .translate-block {
      position: relative;
      z-index: 0;

      &-title {
        // display: none;
        padding: 8px 12px;
        border-radius: 10px;
        cursor: pointer;
        transition: $transition;

        &:hover,
        &:focus {
          transition: $transition;
          background-color: $black_3;
          background-color: $black_2;
        }
      }

      &-content {
        position: absolute;
        top: calc(100% + 8px);
        right: 0;
        z-index: -1;
        background-color: $white;
        border-radius: 10px;
        overflow: hidden;
        transition: $transition;

        button,
        a {
          padding: 8px 16px;
          display: block;
          width: 100%;
          text-align: left;

          &:hover {
            background-color: $white_2;
          }
        }

        &.hide {
          top: 50%;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }
      }
    }

    .burger-button {
      position: relative;
      width: 40px;
      height: 20px;

      span {
        position: absolute;
        height: 2px;
        background-color: $white;
        right: 0;
        left: 0;
        width: 100%;
        margin-left: auto;
        transition: $transition;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          width: 65%;
          top: calc(50% - 1px);
        }

        &:nth-child(3) {
          width: 35%;
          top: calc(100% - 1px);
        }
      }
    }
  }
}

footer.page-footer {
  flex-shrink: 0;
  padding-top: 63px;
  padding-bottom: 57px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: $black_3;
    height: 1px;
    max-width: 1408px;
  }

  .nav-wrap-con {
    display: flex;
    column-gap: 56px;
    white-space: nowrap;

    ul {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      &.nav-col-2 {
        flex-direction: row;
        flex-wrap: wrap;

        li {
          flex: 0 0 50%;
        }
      }
    }
  }

  .right-side {
    text-align: right;

    .mailto {
      svg {
        margin-right: 8px;
      }
    }

    .payments-svg {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-end;

      svg {
        margin-right: 37px;
      }

      a {
        white-space: nowrap;

        svg {
          margin-right: 0;
        }
      }
    }
  }
}

// ========================================================= PAGES =========================================================

.home-page {
  &.banner {
    margin-top: 128px;
    margin-bottom: 205px;

    .left-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      p {
        margin-top: 32px;
        margin-bottom: 56px;
        max-width: 628px;
      }
    }

    .right-side {
      svg {
        // margin-right: -50px;
        // display: block;
        position: relative;
        right: -50px;
      }
    }
  }

  &.payments-info {
    padding-bottom: 110px;

    &.bg-white-first {
      margin-top: 397px;
    }

    .row-title {
      margin-bottom: 104px;

      p {
        margin-left: 41px;
        max-width: 568px;
      }
    }

    .row-content {
      .col-lg-3 {
        display: flex;
        align-items: stretch;
      }

      .custom-cart {
        padding: 24px 24px 24px 32px;
        border-radius: 20px;

        h4 {
          margin-top: 32px;
          margin-bottom: 8px;
        }
      }
    }
  }

  &.explore-new {
    .logos-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: 47px;
      flex: 0 1 100%;

      &-wrap {
        display: flex;
        align-items: center;
        margin-left: 4.3%;
        margin-right: 4.3%;
        margin-bottom: 59px;

        svg {
          opacity: 0.2;
        }
      }
    }

    .col-lg-12 {
      display: flex;
    }

    .img-wrap {
      max-width: 763px;
      margin-left: -10%;
    }

    .content-wrap {
      margin-top: 92px;
      margin-left: 24px;

      * {
        max-width: 628px;
      }

      p {
        margin-top: 29px;
        margin-bottom: 48px;
      }
    }
  }

  &.ehe-keys {
    &.bg-white-last {
      margin-bottom: 375px;
    }

    .row-title {
      margin-bottom: 58px;

      h2 {
        max-width: 519px;
      }

      p {
        max-width: 628px;
      }
    }

    .row-content {
      align-items: flex-start;

      strong {
        font-weight: 700;
      }

      &>div {
        padding-top: 40px;
        padding-bottom: 40px;
        border-top: 1px solid $grey_1;
        // border-bottom: 1px solid $grey_1;
      }

      .content-left-side {
        display: flex;
        align-items: center;

        .custom-cart {
          border-radius: 10px;
          color: $green_2;
          width: 72px;
          height: 72px;
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h3 {
          max-width: 302px;
          margin-left: 48px;
        }
      }

      .link-arrow {
        margin-top: 24px;
      }
    }
  }
}

.payments-page {
  &.banner {
    .left-side {
      h2 {
        max-width: 448px;
      }
    }

    .right-side {
      img {
        position: relative;
        right: -27px;
      }
    }
  }

  &.payments-icons {
    margin-top: 336px;

    .marquee {
      padding-bottom: 120px;

      &-reverse {
        // margin-top: 66px;
        margin-top: -54px;
      }
    }

    .custom-cart {
      width: 128px;
      height: 128px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  &.carts-wrap {
    padding-top: 110px;
    padding-bottom: 72px;

    .custom-cart {
      width: 96px;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
    }

    h4 {
      margin-top: 56px;
      margin-bottom: 16px;
    }

    .link-arrow {
      margin-top: 16px;
    }

    .row {
      margin-bottom: 36px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.chose-block {
    .container {
      padding-top: 112px;
      padding-bottom: 168px;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 16px;
        right: 16px;
        height: 1px;
        background-color: $grey_1;
        width: calc(100% - 32px);
      }
    }

    .col-title {
      p {
        margin-top: 48px;
        max-width: 328px;
      }
    }

    .row {
      margin-bottom: 56px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .custom-cart {
      padding: 24px;
      border-radius: 20px;
      min-height: 288px;

      &-wrap {
        display: flex;
        // align-items: s;
        // margin-bottom: 56px;
      }

      h4 {
        margin-top: 32px;
        margin-bottom: 16px;
      }
    }
  }

  &.tech-features {
    &.bg-white-last {
      margin-bottom: 570px;

      .bg-white-last-bg-wrap {
        bottom: -398px;
      }
    }

    .row-title {}


    .col-tabs {
      &-navigation {
        margin-top: 152px;
        margin-bottom: 116px;

        .slick-dots {
          position: unset;
          text-align: left;

          li,
          button {
            padding: 0;
            margin: 0;
            @include body_1;
            @include body_bold;
            width: unset;
            height: unset;
            color: $black_2;
            transition: $transition;

            &::before {
              content: unset;
            }
          }

          button {
            padding: 14px 10px;
            border-bottom: 4px solid transparent;
            margin-right: 24px;
          }

          li.slick-active {
            button {
              border-color: $green_1;
            }
          }
        }
      }

      &-wrap {
        margin-bottom: 56px;

        .slick-list {
          overflow: visible;
        }
      }

      &-item {
        margin-right: 72px;

        h3 {
          margin-bottom: 40px;
        }
      }
    }

    .link-button {
      color: $green_1;

      &:hover,
      &:focus {
        color: $green_2;
      }
    }
  }
}

.tech-features-page {
  &.banner {
    .right-side {
      text-align: right;

      img {
        margin-top: -10%;
        margin-bottom: -15%;
      }
    }
  }

  &.main-carts-sect {
    margin-top: 300px;
    margin-bottom: 460px;

    .row-item {
      align-items: flex-start;
      position: relative;
      padding-bottom: 104px;
      margin-bottom: 104px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 16px;
        height: 1px;
        background-color: $grey_1;
      }

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;

        &::after {
          content: unset;
        }
      }
    }

    .item {
      &-left-side {
        display: flex;
        align-items: center;
        column-gap: 64px;
        row-gap: 25px;

        .custom-cart {
          border-radius: 10px;
          width: 104px;
          height: 104px;
          display: flex;
          align-content: center;
          justify-content: center;
        }
      }
    }
  }
}

.antifraud-page {
  &.banner {}

  &.main-carts-sect {
    margin-top: 344px;
    margin-bottom: 517px;

    .bg-white-last-bg-wrap {
      bottom: -326px;
    }

    .row-title {
      margin-bottom: 216px;
    }

    .row-content {
      .custom-cart {
        width: 96px;
        height: 96px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      h4 {
        margin-top: 56px;
        margin-bottom: 16px;
      }
    }
  }
}

.contact-page {
  margin-top: 80px;
  margin-bottom: 240px;

  br {
    display: none;
  }

  input,
  textarea {
    max-width: 100%;
    // width: 100%;
  }

  .row-title {
    margin-bottom: 40px;

    h2 {
      margin-bottom: 40px;
    }
  }

  .row-content {
    .link-wrap {
      display: flex;
      align-items: center;
      column-gap: 16px;
      @include body_bold;
      margin-bottom: 48px;
    }

    .custom-cart {
      border-radius: 8px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  form {
    textarea {
      width: 100%;
    }

    .col-lg-6,
    .col-lg-12 {
      margin-bottom: 48px;
    }

    .col-lg-12 {
      margin-bottom: 40px;
    }

    .wpcf7-list-item {
      margin-left: 0;
    }

    .placeholder {
      position: absolute;
      z-index: 1;
      top: 16px;
      left: 16px;
      transition: $transition;
      pointer-events: none;

      font-family: "Mulish", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: $grey_2;

      &~span {

        input,
        textarea {
          &::placeholder {
            font-size: 0px;
          }
        }
      }
    }

    p {
      position: relative;

      &.not-empty {
        .placeholder {
          font-size: 14px;
          top: 8px;
        }
      }
    }

    .wpcf7-checkbox {
      max-width: calc(100vw - 32px);
      width: 110%;
      display: block;

      label {
        display: flex;
        @include body_2;
      }

      a {
        text-decoration: underline;
      }

      .wpcf7-list-item-label {
        position: relative;
        display: inline-block;
        margin-left: 16px;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -40px;
          z-index: 1;
          width: 24px;
          height: 24px;
          border: 1px solid $grey_2;
          border-radius: 4px;
          transition: $transition;
        }
      }

      input[type="checkbox"] {
        opacity: 0.01;
        display: inline-block;
        width: 24px;
        height: 24px;
        flex: 0 0 24px;

        &:checked {
          &~.wpcf7-list-item-label {
            &::before {
              background-color: $black_3;
            }
          }
        }
      }

      label {
        cursor: pointer;
      }
    }
  }
}

.merchant-portal-page {
  &.banner {

    .right-side {
      overflow: hidden;

      img {
        position: relative;
        right: -18%;
      }
    }
  }

  &.keep-track {
    padding-bottom: 96px;

    &.bg-white-first {
      margin-top: 304px;

      .bg-white-first-bg-wrap {
        top: -148px;
      }
    }

    .bg-white-first-circle {
      top: -148px - 72px;
    }

    .title {
      margin-bottom: 32px;
    }

    .item {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      column-gap: 24px;

      .custom-cart {
        width: 44px;
        height: 44px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &.start-analyzing {

    .container {
      position: relative;
      padding-top: 104px;
      padding-bottom: 127px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        background-color: $grey_1;
        left: 16px;
        right: 16px;
        width: calc(100% - 32px);
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    .row-title {
      margin-bottom: 97px;
    }

    .row-content {
      align-items: flex-end;

      .item {
        margin-bottom: 80px;

        &:last-child {
          margin-bottom: 0;
        }

        &-header {
          display: flex;
          align-items: center;
          column-gap: 48px;
          margin-bottom: 40px;
        }

        .custom-cart {
          width: 72px;
          height: 72px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $green_2;
        }

        &-main {}
      }
    }
  }

  &.additional-benefits {
    padding-top: 86px;
    padding-bottom: 70px;
    margin-bottom: 512px;

    .col-title {
      margin-bottom: 104px;
    }

    .row {
      // align-items: stretch;
    }

    .col-lg-3 {
      display: flex;
      align-items: stretch;
    }

    .custom-cart {
      // width: ;
      border-radius: 20px;
      background-color: $white;
      padding: 24px;

      p {
        margin-top: 32px;
      }
    }
  }

}

.integration-page {
  &.banner {
    .left-side {
      justify-content: flex-start;
    }
  }

  &.effortless {
    padding-bottom: 208px;

    &.bg-white-first {
      margin-top: 380px;

      .bg-white-first-bg-wrap {
        top: -251px;
      }

      .bg-white-first-circle {
        top: -251px - 72px;
      }
    }

    .row-title {
      margin-bottom: 231px;
    }

    .row-content {
      .col-title {
        margin-bottom: 89px;

        h3 {
          max-width: 760px;
        }
      }

      .col-lg-3 {
        display: flex;
        align-items: stretch;
      }

      .custom-cart {
        border-radius: 20px;
        padding: 24px;
        min-height: 256px;

        p {
          margin-top: 32px;
        }
      }
    }
  }

  &.types-of-integration {
    .container {
      position: relative;
      padding-top: 80px;
      padding-bottom: 152px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 16px;
        right: 16px;
        height: 1px;
        background-color: $grey_1;
        width: calc(100% - 32px);
      }


      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    .row-title {
      margin-bottom: 85px;

      .col-navigation {
        display: flex;
        column-gap: 32px;

        .slick-dots {
          position: unset;
          text-align: right;

          button,
          li {
            width: unset;
            height: unset;
            padding: 0;
            margin: 0;

            &::before,
            &::after {
              content: unset;
            }
          }


          button {
            padding: 14px 10px;
            border-bottom: 4px solid transparent;
            transition: $transition;
            @include body_1;
            @include body_bold;
            color: $black_2;

            &:last-child {
              margin-right: 0;
            }

            &.current {
              border-color: $green_1;
            }

            &:hover {
              transition: $transition;
              border-color: rgba($green_1, 0.4);
            }
          }

          li {
            margin-right: 32px;

            &:last-child {
              margin-right: 0;
            }

            &.slick-active {
              button {
                border-color: $green_1;
              }
            }
          }
        }
      }
    }

    .row-content {
      .slider-types {
        &-item {
          h3 {
            margin-bottom: 48px;
          }

          .custom-cart {
            border-radius: 10px;
            flex: 0 0 64px;
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $green_2;

            &-wrap {
              display: flex;
              align-items: center;
              column-gap: 48px;
              margin-bottom: 48px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        &-dots {
          .slick-dots {
            position: unset;

            button,
            li {
              width: unset;
              height: unset;
              padding: 0;
              margin: 0;

              &::before,
              &::after {
                content: unset;
              }
            }

            button {
              width: 16px;
              height: 16px;
              border-radius: 100%;
              background-color: #E0DFE9;
              filter: drop-shadow(0px 6px 12px rgba(51, 61, 56, 0.10)) drop-shadow(0px 23px 23px rgba(51, 61, 56, 0.09)) drop-shadow(0px 51px 31px rgba(51, 61, 56, 0.05)) drop-shadow(0px 91px 36px rgba(51, 61, 56, 0.01)) drop-shadow(0px 142px 40px rgba(51, 61, 56, 0.00));
            }

            li {
              width: 16px;
              height: 16px;
              margin-left: 12px;
              margin-right: 12px;

              &.slick-active {
                button {
                  background-color: $green_1;
                }
              }
            }
          }
        }
      }

      .slick-dotted.slick-slider {
        margin-bottom: 160px;
      }
    }
  }

  &.additional-benefits {
    padding-top: 80px;


    &.bg-white-last {
      // padding-bottom: 433px;
      margin-bottom: 637px;

      .bg-white-last-bg-wrap {
        bottom: -433px;
      }
    }

    .row-title {
      margin-bottom: 64px;
    }

    .custom-cart {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      &-wrap {
        margin-bottom: 40px;
        display: flex;
        column-gap: 48px;
        align-items: center;
      }
    }
  }
}

.faq-page {

  &.banner {
    margin-top: 0;
  }


  &.main-content {
    margin-bottom: 617px;

    .nav-wrap {
      padding: 48px 32px 48px 40px;
      box-shadow: 0px 6px 12px 0px rgba(51, 61, 56, 0.10), 0px 23px 23px 0px rgba(51, 61, 56, 0.09), 0px 51px 31px 0px rgba(51, 61, 56, 0.05), 0px 91px 36px 0px rgba(51, 61, 56, 0.01), 0px 142px 40px 0px rgba(51, 61, 56, 0.00);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      max-width: 376px;
      position: sticky;
      top: 95px;

      a {
        color: $black_2;
        @include body_2;
        @include body_bold;

        &:hover,
        &:focus,
        &.current {
          color: $green_1;
        }
      }
    }

    .nav-content {
      &-title {
        // padding-top: 50vh;
        // margin-top: calc(-50vh + 32px);
        padding-top: 32px + 73px;
        margin-top: -73px;
        @include body_bold;
      }

      &-main {
        // padding-top: 50vh;
        // margin-top: calc(-50vh + 16px);
        margin-top: 16px;
        @include body_2;
        display: inline-block;

        a {
          color: $black_2;

          &:hover,
          &:focus {
            color: $green_1;
          }
        }
      }
    }
  }
}

.not-found-page {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 73px - 256px);
  padding-top: 15vh;
  padding-bottom: 15vh;

  .sect-bg {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 10%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    margin-bottom: 48px;
  }

  p {
    margin-bottom: 8px;
  }

  .button {
    margin-top: 40px;
  }
}

.policy-page {
  .wp-block-heading {
    @include body_1;
    @include body_bold;
    padding-top: 105px;
    margin-top: -73px;
  }

  p {
    margin-top: 16px;

    u {
      text-decoration: none;
    }

    strong {
      font-weight: 700;
      margin-right: 6px;
      text-decoration: none;
    }
  }

  a {
    color: $green_1;
  }
}

// ============================================ COMPONENTS ============================================

.feedback-container {
  margin-top: 375px;
  margin-bottom: 208px;

  .row-title {
    margin-bottom: 127px;

    .color-grey_2 {
      margin-bottom: 8px;
    }

    p {
      max-width: 628px;
      margin-top: 8px;
    }
  }

  .row-content {
    .slider {
      &-nav {
        rect {
          transition: $transition;
        }

        #slider-nav-prev,
        #slider-nav-next {

          &:hover,
          &:focus {
            rect {
              fill: $black_3;
            }
          }
        }

        #slider-nav-next {
          margin-left: 56px;
        }
      }
    }

    .custom-slider {
      .slick-track {
        display: flex;
      }

      &-item {
        border-left: 4px solid $green_2;
        padding-left: 24px;
        margin-right: 82px;
        display: flex;
        flex-direction: column;
        height: auto;

        >p {
          margin-bottom: auto;
        }

        .feedback-author {
          margin-top: auto;
          display: flex;
          margin-top: 16px;

          .color-grey_2 {
            margin-left: 29px;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              left: -17px;
              margin: auto;
              width: 5px;

              height: 5px;
              background-color: $grey_2;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}

.contact-container {
  margin-top: 191px;
  display: flex;
  align-items: stretch;
  column-gap: 32px;
  margin-bottom: 165px;

  .bg-white {
    border-radius: 50px;
    position: relative;
    z-index: 1;
  }

  .white-circle {
    border-radius: 100%;
    overflow: hidden;
    border: 10px solid $black_1;
    position: absolute;
    z-index: 10;
    top: 50%;
    right: -81px;
    margin: auto;
    transform: translateY(-50%) rotate(-90deg);
  }

  .left-side {
    padding: 92px 132px 127px 88px;
  }

  .right-side {
    padding: 48px 75px 51px 96px;
    z-index: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 616px;

    .mailto {
      display: flex;
      align-items: center;
      column-gap: 16px;
      margin-top: 32px;
      margin-bottom: auto;
      color: $black_2;
      transition: $transition;
      @include body_bold;

      .custom-cart {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover,
      &:focus {
        transition: $transition;
        color: $green_1;
      }
    }
  }
}

// ========================================================= OTHER =========================================================

#trp-floater-ls {
  opacity: 0.01;
  visibility: hidden;
  pointer-events: none;
}

// Always in the end
@import "_media.scss";
// ___________________